<template>
  <div
    v-if="Object.keys(teacher_data).length"
    id="user-profile"
  >
    <b-row>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Change Profile Photo
          </b-card-title>
          <b-avatar
            :src="teacher_data.avatar"
            size="330px"
            rounded
          />

          <validation-observer
            ref="updateAvatarForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="updateAvatar"
            >
              <div>
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <b-form-group
                    label="Update Image"
                    label-for="change-avatar"
                  >
                    <b-form-file
                      id="avatar"
                      v-model="avatar"
                      :state="errors.length > 0 ? false : null"
                      name="avatar"
                      accept=".jpg,.jpeg,.png"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- submit button -->
                <b-button
                  variant="primary"
                  block
                  size="sm"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Update Image" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="8"
      >
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Update Details
          </b-card-title>
          <validation-observer
            ref="updateDetailsForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="updateDetails"
            >
              <b-row>
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- firstname -->
                  <b-form-group
                    label="First Name"
                    label-for="first_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        id="first_name"
                        v-model="first_name"
                        :state="errors.length > 0 ? false : null"
                        name="first-name"
                        placeholder="John"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- lastname -->
                  <b-form-group
                    label="Last Name"
                    label-for="last_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-input
                        id="last_name"
                        v-model="last_name"
                        :state="errors.length > 0 ? false : null"
                        name="Last Name"
                        placeholder="Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="personal_email"
                        v-model="teacher_email"
                        :state="errors.length > 0 ? false : null"
                        name="register-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Phone Number -->
                  <b-form-group
                    label="Phone Number"
                    label-for="phone_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                      vid="phone_number"
                    >
                      <b-form-input
                        id="personal_phone_number"
                        v-model="phone_number"
                        :state="errors.length > 0 ? false : null"
                        name="phone-number"
                        placeholder="07000000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
                class="w-50 float-right"
                :disabled="invalid || isLoading"
              >
                {{ !isLoading ? "Update Details" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Update Password
          </b-card-title>
          <validation-observer
            ref="updatePasswordForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="updatePassword"
            >
              <b-row>
                <b-col
                  lg="4"
                  sm="12"
                >
                  <b-form-group
                    label="Enter Old Password"
                    label-for="old-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Old Password"
                      rules="required"
                      vid="old_password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="old_password"
                          v-model="old_password"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="Old Password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="4"
                  sm="12"
                >
                  <b-form-group
                    label="Enter New Password"
                    label-for="new-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      rules="required"
                      vid="new_password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="new_password"
                          v-model="new_password"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="New Password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="4"
                  sm="12"
                >
                  <b-form-group
                    label="Confirm New Password"
                    label-for="confirm-new-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password Confirmation"
                      rules="required"
                      vid="confirm_new_password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password_confirmation"
                          v-model="new_password_confirm"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="Password Confirmation"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                size="sm"
                type="submit"
                class="w-25 float-right"
                :disabled="invalid || isLoading"
              >
                {{ !isLoading ? "Update Password" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BButton, BFormFile, BFormGroup, BFormInput, BRow, BCol, BAvatar, BCard, BCardTitle, BSpinner, BInputGroupAppend, BInputGroup, BForm,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Profile',
  components: {
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormGroup,
    BAvatar,
    BCard,
    BCardTitle,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    // Validations
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      teacher_data: {},
      first_name: '',
      last_name: '',
      teacher_email: '',
      phone_number: '',
      old_password: '',
      new_password: '',
      new_password_confirm: '',
      avatar: null,
      // validation rules
      required,
      email,
    }
  },
  async mounted() {
    await this.$http.get('admin/profile')
      .then(response => {
        this.teacher_data = response.data
        this.first_name = this.teacher_data.first_name
        this.last_name = this.teacher_data.last_name
        this.teacher_email = this.teacher_data.email
        this.phone_number = this.teacher_data.phone_number
      })
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    updateAvatar() {
      this.isLoading = true
      this.$refs.updateAvatarForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('avatar', this.avatar)
          this.$http.post('admin/avatar/update', formData)
            .then(response => {
              this.avatar = null
              this.teacher_data = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updateDetails() {
      this.isLoading = true
      this.$refs.updateDetailsForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('first_name', this.first_name)
          formData.append('last_name', this.last_name)
          formData.append('email', this.teacher_email)
          formData.append('phone_number', this.phone_number)
          this.$http.post('admin/profile/update', formData)
            .then(response => {
              this.teacher_data = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              this.old_password = ''
              this.new_password = ''
              this.new_password_confirm = ''
              const userData = JSON.parse(localStorage.getItem('userData'))
              userData.data = response.data.data
              localStorage.setItem('userData', JSON.stringify(userData))
            })
            .catch(error => {
              console.log(error.response.data)
              this.$refs.updatePasswordForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    updatePassword() {
      this.isLoading = true
      this.$refs.updatePasswordForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('old_password', this.old_password)
          formData.append('new_password', this.new_password)
          formData.append('new_password_confirm', this.new_password_confirm)
          this.$http.post('admin/password/update', formData)
            .then(response => {
              this.teacher_data = response.data.data
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              this.old_password = ''
              this.new_password = ''
              this.new_password_confirm = ''
            })
            .catch(error => {
              console.log(error.response.data)
              this.$refs.updatePasswordForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Please Check Entered Details',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
